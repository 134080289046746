.backdrop {
	position: fixed;
	z-index: 10;
	background-color: rgba(0, 0, 0, 0.75);
	width: 100%;
	height: 100vh;
	top: 0;
	left: 0;
}
.modal {
	background-color: #324235;
	width: 300px;
	text-align: center;
	z-index: 50;
	position: fixed;
	top: 40%;
	left: calc(50% - 150px);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 10px;
	p {
		background-color: #324235;
		max-width: 300px;
		padding: 20px;
		margin: 5px;
	}
	button {
		z-index: 51;
		outline: none;
		border: none;
		padding: 10px;
		color: white;
		font-size: 21px;
		background-color: rgb(231, 171, 18);
		box-shadow: 0px 0px 4px 2px rgba(231, 171, 18, 0.75);
		cursor: pointer;
		transition: 0.3s ease;
		&:hover {
			transform: perspective(500px) translateZ(40px);
		}
	}
}

@media (max-width: 1300px) {
	.modal {
		left: calc(50% - 200px);
	}
}
@media (max-width: 750px) {
	.modal {
		left: calc(50% - 180px);
	}
}

@media (max-width: 380px) {
	.modal {
		margin: 5px;
	}
}
