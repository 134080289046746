body {
	margin: 0;
	padding: 0;
	font-family: "Lora", serif;
}
html {
	scroll-behavior: smooth;
}
.seperation-bar {
	width: 100%;
	height: 15px;
	background: #8dad93;
}
