@import url("https://fonts.googleapis.com/css2?family=Lora&family=Montserrat:wght@400;600&display=swap");
@import "./partials/layout";
@import "./partials/reusableStyles";
@import "./partials/navigation";
@import "./partials//homeSection";
@import "./partials/aboutSection";
@import "./partials/workSection";
@import "./partials/contactSection";
@import "./partials/spinner";
@import "./partials/modalBackdrop";
