.contact_section {
	min-height: 80vh;
	display: flex;
	flex-direction: column;

	justify-content: center;
	background: #324235;
	color: #fff6e6;
	.contact-section-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		h2 {
			display: none;
			color: #fff6e6;
		}
		.contact-section-content-container {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			padding: 20px;
			gap: 50px;
			.form-error-container {
				background: #c73e1d;
				height: 100px;
				display: flex;
				flex-direction: column;
				align-items: center;
				padding: 5px;
				box-shadow: 0px 0px 18px -2px rgba(0, 0, 0, 0.75);

				button {
					cursor: pointer;
					outline: none;
					border: none;
					width: 200px;
					padding: 10px;
					background: #2a4f87;
					color: white;
					font-size: 18px;
					box-shadow: 0px 0px 18px 3px rgba(0, 0, 0, 0.75);
					transition: 0.3s ease;
					&:hover {
						background: #428959;
					}
				}
			}
			.contact-section-text {
				display: flex;
				flex-direction: column;
				gap: 40px;
				p {
					font-family: "Montserrat", sans-serif;
					font-size: 38px;
					font-weight: 600;
					margin: 0;
				}
				.socials {
					display: flex;
					flex-direction: row;
					align-items: center;
					gap: 100px;
					a {
						text-decoration: none;
						color: #fff6e6;

						font-size: 46px;
						transition: 0.3s ease;
						&:hover {
							color: #e7ab12;
							transform: perspective(500px) translateZ(100px);
						}
					}
					.socials-filler-bar {
						height: 2px;
						width: 100%;
						background-color: #fff6e6;
					}
				}
				.footer-nav {
					display: flex;
					flex-direction: column;
					gap: 10px;
					.footer-nav-link {
						cursor: pointer;
						border-left: 1px solid #e7ab12;
						padding-left: 10px;
						width: 80px;
						transition: 0.3s ease;
						font-style: italic;
						&:hover {
							color: #e7ab12;
						}
					}
				}
			}
		}
	}
}
.contact-section-form {
	width: 400px;
	margin: 0 auto;
	padding-top: 40px;
	padding-bottom: 20px;
	border: 1px solid #fff6e6;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 40px;
	box-shadow: 0px 0px 18px -2px rgba(0, 0, 0, 0.75);
}

.input-container,
.textarea-container {
	position: relative;
	width: 250px;
}

.label,
.textarea-label {
	position: absolute;
	width: 100%;
	top: -20px;
	left: 0px;
	transition: 1s all;
}
.label::after {
	content: "";
	position: absolute;
	top: 70px;
	left: 0px;
	width: 0%;
	background: rgb(146, 58, 180);
	background: linear-gradient(90deg, #8dad93 0%, #e7ab12 50%, #5c5b52 100%);
	height: 5px;
	transition: 1s all;
}

.input:focus + .label::after {
	width: 108%;
}
.input {
	width: 100%;
	height: 35px;
	border: 0px;
	background: #efefef;
	padding: 10px;
}
.textarea {
	width: 100%;
	height: 200px;
	border: 0px;
	background: #efefef;
	resize: none;
	outline: none;
	padding: 10px;
}
.textarea-label::after {
	content: "";
	position: absolute;
	top: 235px;
	left: 0px;
	width: 0%;
	background: rgb(146, 58, 180);
	background: linear-gradient(90deg, #8dad93 0%, #e7ab12 50%, #5c5b52 100%);
	height: 5px;
	transition: 1s all;
}
.textarea:focus + .textarea-label::after {
	width: 108%;
}
.input:focus {
	outline: none;
}
.error-message {
	margin-top: -30px;
	color: red;
	font-size: 13px;
}

.form-button-container {
	position: relative;
	overflow: hidden;
	box-shadow: 0px 0px 5px 6px rgba(0, 0, 0, 0.75);
	border-radius: 20px;
	transition: 0.3s ease;
}
.form-button-container:hover {
	transform: perspective(500px) translateZ(20px);
	box-shadow: 0 0 5px 10px rgba(0, 0, 0, 0.75);
	transition: 0.3s ease;
	cursor: pointer;
}
.form-button {
	border: none;
	background-color: transparent;
	padding: 10px 30px;
	border-radius: 30px;
	color: white;
	position: relative;
	z-index: 10;
	transition: 0.3s ease;
	font-size: 16px;
	cursor: pointer;
}
.form-button:hover {
	text-decoration: underline;
}
.form-button-background {
	background: rgb(146, 58, 180);
	background: linear-gradient(90deg, #8dad93 0%, #e7ab12 70%, #5c5b52 100%);

	height: 650px;
	width: 650px;
	position: absolute;
	top: 0px;
	animation: moveGradient 4s linear alternate infinite;
}
@keyframes moveGradient {
	0% {
		top: 0px;
		left: 0px;
	}

	100% {
		left: -400px;
		top: -400px;
	}
}

@media (max-width: 1125px) {
	.contact_section {
		.contact-section-content {
			.contact-section-content-container {
				.contact-section-text {
					p {
						font-size: 28px;
					}
					.socials {
						gap: 50px;
					}
				}
			}
		}
	}
}

@media (max-width: 960px) {
	.contact_section {
		.contact-section-content {
			.contact-section-content-container {
				.contact-section-text {
					p {
						font-size: 18px;
					}
					.socials {
						gap: 20px;
					}
				}
			}
		}
	}
}

@media (max-width: 800px) {
	.contact_section {
		.contact-section-content {
			h2 {
				display: block;
			}
			.contact-section-content-container {
				flex-direction: column-reverse;
				.form-error-container {
					height: 120px;
				}
			}
		}
	}
}

@media (max-width: 445px) {
	.contact-section-form {
		width: 250px;

		border: none;
		box-shadow: none;
	}
	.textarea-container,
	.input-container {
		width: 200px;
	}

	.contact_section {
		.contact-section-content {
			.contact-section-content-container {
				.form-error-container {
					height: 150px;
				}
				.contact-section-text {
					p {
						font-size: 14px;
						margin: 0 auto;
					}
					.socials {
						gap: 20px;
						margin: 0 auto;
						.socials-filler-bar {
							display: none;
						}
					}
					.footer-nav {
						width: 100px;
					}
				}
			}
		}
	}
	.textarea:focus + .textarea-label::after {
		width: 110%;
	}
	.input:focus + .label::after {
		width: 110%;
	}
}

@media (max-width: 300px) {
	.contact-section-form {
		width: 230px;
	}
}
