.home_section {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	background-color: #fff6e6;
}

.home_content_container {
	display: flex;
	max-width: 1200px;
	padding: 10px;
	padding-top: 50px;
	margin: 0 auto;
	justify-content: center;
	align-items: center;
	color: #5c5b52;
	gap: 100px;
	.home_content_text_container {
		p {
			text-transform: uppercase;
			font-size: 16px;
		}
		h1 {
			font-family: "Montserrat", sans-serif;
			font-size: 64px;
			font-weight: 600;
			width: 400px;
			text-shadow: 1px 1px 1px black;
		}
		.button-container {
			position: relative;
			overflow: hidden;
			max-width: 220px;
			border-radius: 15px 0 15px 0;
			margin-top: 50px;
			padding: 5px;
			box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.75);
			.download-btn {
				font-family: "Montserrat", sans-serif;
				font-size: 21px;
				font-weight: 600;
				position: relative;
				z-index: 10;
				background: none;
				width: 100%;
				border: 0px;
				height: 100%;
				color: #fff;
				padding: 10px;
				font-weight: bold;
				&:hover {
					cursor: pointer;
					text-decoration: underline;
				}
			}
			.button-background {
				background: rgb(146, 58, 180);
				background: linear-gradient(
					90deg,
					#8dad93 0%,
					#e7ab12 50%,
					#5c5b52 100%
				);
				width: 800px;
				height: 800px;
				position: absolute;
				top: 0px;
				animation: moveGradient 4s linear alternate infinite;
			}

			@keyframes moveGradient {
				0% {
					top: 0px;
					left: 0px;
				}

				100% {
					left: -500px;
					top: -500px;
				}
			}
		}
	}
	.profile-picture {
		width: 400px;
		border-radius: 30px 0 30px 0;
		box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
	}
}

@media (max-width: 950px) {
	.home_content_container {
		gap: 50px;
		.profile-picture {
			width: 300px;
		}
	}
}
@media (max-width: 800px) {
	.home_content_container {
		gap: 30px;
		.profile-picture {
			width: 200px;
		}
	}
}
@media (max-width: 699px) {
	.home_content_container {
		gap: 30px;
		flex-direction: column-reverse;
		.home_content_text_container {
			text-align: center;
		}
		.profile-picture {
			border-radius: 0;
			box-shadow: none;
		}
		.button-container {
			margin: 0 auto;
		}
	}
}

@media (max-width: 430px) {
	.home_content_container {
		.home_content_text_container {
			p {
				text-transform: uppercase;
				font-size: 14px;
			}
			h1 {
				font-size: 42px;

				width: 100%;
			}
		}
	}
}
