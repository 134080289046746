.navigation {
	position: sticky;
	top: 0px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 80px;
	align-items: center;
	padding: 0 50px;
	background-color: #fff6e6;
	z-index: 100;

	.logo_container {
		a {
			cursor: pointer;
		}
		.logo {
			width: 100px;
		}
	}

	.nav-links_container {
		display: flex;
		gap: 20px;
		align-items: center;
		a {
			font-size: 16px;
			color: #324235;
			font-family: "Montserrat", sans-serif;
			text-transform: uppercase;
			font-weight: 400;
			cursor: pointer;
			border-bottom: 2px solid transparent;
			transition: 300ms ease;
			&:hover {
				border-bottom: 2px solid #e7ab12;
				transition: 300ms ease;
			}
		}
		button {
			font-family: "Montserrat", sans-serif;
			font-size: 16px;
			color: #8dad93;
			font-weight: 600;
			outline: none;
			background-color: transparent;
			border: 2px solid #8dad93;
			border-radius: 15px 0 15px 0;
			margin-left: 50px;
			padding: 10px 20px;
			cursor: pointer;
			transition: 300ms ease;
			&:hover {
				background-color: #8dad93;
				color: #fff6e6;
			}
		}
		.active {
			border-bottom: 2px solid #e7ab12;
			transition: 300ms ease;
		}
		.active-contact {
			background-color: #8dad93;
			color: #fff6e6;
			transition: 300ms ease;
		}
	}
}

@media (max-width: 690px) {
	.navigation {
		padding: 0 10px;
		.nav-links_container {
			button {
				margin-left: 20px;
				font-size: 14px;
			}
			a {
				font-size: 14px;
			}
		}
	}
}
@media (max-width: 500px) {
	.navigation {
		.logo_container {
			.logo {
				width: 80px;
			}
		}
		.nav-links_container {
			gap: 10px;
			button {
				font-size: 12px;
				margin-left: 10px;
				padding: 7px 10px;
			}
			a {
				font-size: 12px;
			}
		}
	}
}

@media (max-width: 390px) {
	.navigation {
		.logo_container {
			.logo {
				width: 60px;
			}
		}
		.nav-links_container {
			gap: 5px;
			button {
				font-size: 10px;
				margin-left: 5px;
				padding: 7px 10px;
			}
			a {
				font-size: 10px;
			}
		}
	}
}
@media (max-width: 300px) {
	.navigation {
		.logo_container {
			.logo {
				width: 50px;
			}
		}
		.nav-links_container {
			gap: 5px;
			button {
				font-size: 10px;
				margin-left: 5px;
				padding: 7px 5px;
			}
			a {
				font-size: 10px;
			}
		}
	}
}
