.Arrow_btn {
	cursor: pointer;
	padding-left: 150px;
	padding-bottom: 20px;
	margin-top: 20px;
}
.title {
	font-family: "Montserrat", sans-serif;
	font-size: 36px;
}

@media (max-width: 1300px) {
	.Arrow_btn {
		padding-left: 10px;
	}
}
@media (max-width: 550px) {
	.title {
		font-size: 28px;
	}
}
