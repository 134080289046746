.work_section {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	background: #fff6e6;
	.work-section-content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		h2 {
			color: #5c5b52;
		}
		.cards-container {
			max-width: 950px;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			gap: 50px;

			.card {
				display: flex;
				flex-direction: column;
				justify-content: center;
				width: 250px;
				background: white;
				padding: 0 20px 20px 20px;
				box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.15);
				border-radius: 5px;
				.buttons-container-work {
					display: flex;
					justify-content: space-between;
				}
				.github-icon {
					width: 40px;
					cursor: pointer;
				}
				img {
					width: 250px;
				}
				.holidaze-image {
					width: 100px;
					margin: 0 auto;
				}
				.shoefront-image {
					width: 150px;
					margin: 0 auto;
				}
				.work-button-container {
					position: relative;
					overflow: hidden;
					max-width: 150px;
					border-radius: 15px;

					padding: 5px;
					box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.75);
					.work-button {
						font-family: "Montserrat", sans-serif;
						font-size: 16px;
						font-weight: 600;
						position: relative;
						z-index: 10;
						background: none;
						width: 100%;
						border: 0px;
						height: 100%;
						color: #fff;

						font-weight: bold;
						&:hover {
							cursor: pointer;
							text-decoration: underline;
						}
					}
					.work-button-background {
						background: rgb(146, 58, 180);
						background: linear-gradient(
							90deg,
							#8dad93 0%,
							#e7ab12 50%,
							#5c5b52 100%
						);
						width: 600px;
						height: 600px;
						position: absolute;
						top: 0px;
						animation: moveGradient 4s linear alternate infinite;
					}

					@keyframes moveGradient {
						0% {
							top: 0px;
							left: 0px;
						}

						100% {
							left: -500px;
							top: -500px;
						}
					}
				}
			}
		}
	}
}
.shoefront-card,
.holidaze-card {
	p {
		padding-bottom: 60px;
	}
}

.krokvag-card {
	img {
		padding-top: 20px;
	}
}

@media (max-width: 390px) {
	.work_section {
		.work-section-content {
			.cards-container {
				.card {
					width: 200px;
					img {
						width: 200px;
					}
				}
			}
		}
	}
}
