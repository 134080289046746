.about_section {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	background: white;
	.about-section-content {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		h2 {
			color: #5c5b52;
		}
		p {
			font-family: "Lora", serif;
			max-width: 1200px;
			padding: 10px 40px;
			font-size: 28px;
			line-height: 62px;
			.about-me-kontakt-link {
				font-style: italic;
				border-bottom: 1px solid #e7ab12;
				cursor: pointer;
			}
		}
	}
}

@media (max-width: 690px) {
	.about_section {
		.about-section-content {
			p {
				font-size: 22px;
				line-height: 50px;
			}
		}
	}
}
@media (max-width: 550px) {
	.about_section {
		.about-section-content {
			p {
				font-size: 20px;
				line-height: 45px;
			}
		}
	}
}
@media (max-width: 450px) {
	.about_section {
		.about-section-content {
			p {
				padding: 20px;
				font-size: 18px;
				line-height: 40px;
			}
		}
	}
}

@media (max-width: 330px) {
	.about_section {
		.about-section-content {
			p {
				font-size: 16px;
			}
		}
	}
}
